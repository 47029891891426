import './App.css';
import LandingPage from './components/LandingPage.js';
import Experience from './components/Experience.js';
import Header from './components/Header.js';
import { useEffect, useState } from 'react';
import BottomBar from './components/BottomBar.js';
import Sidebar from './components/SideBar.js';
import Education from './components/Education.js';
import ContactMe from './components/ContactMe.js';
import useTheme from './hooks/useTheme.js'

function App() {
  const [headerVisible, setHeaderVisible] = useState(false);
  const [theme] = useTheme();
  localStorage.setItem('theme', theme);


  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > 100) { // Adjust this value as needed
        setHeaderVisible(true);
      } else {
        setHeaderVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);
  return (
    <div className="App">
      <Header className={headerVisible ? 'header visible' : 'header'}/>
      <LandingPage />
      <Sidebar id="sidebar"/>
      <Experience id="experience"/>
      <Education id="education"/>
      <ContactMe id="contactme"/>
      <BottomBar className={headerVisible ? ' visible' : ''}/>
    </div>
  );
}

export default App;
