import React from 'react';
import '../stylesheets/ContactMe.css'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactMe = () => {
  return (
    <div className='contactme'>
        <h1>Contact Me</h1>
        <div className="social-media-links-contactme">
        <a href='https://www.instagram.com/akshithbellare/?hl=en' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className='instagram-icon icon' />
        </a>
        <a href='https://www.linkedin.com/in/akshithbellare/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} className='linkedin-icon icon' />
        </a>
        <a href='https://x.com/xakshithbellare' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} className='linkedin-icon icon' />
        </a>
        <a href='href="mailto:akshibellare@gmail.com"' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} className='linkedin-icon icon' />
        </a>
      </div>
    </div>
  );
};

export default ContactMe;
