import React from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

const Education = () => {
  return (
    <div className='education'>
      <h1>EDUCATION</h1>

      <div className='experience-cards'>
        <div className='experience-card'>
          <h2>HIGH SCHOOL</h2>
          <a href='https://jgcs.edu.in/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <h3>SCHOOL : Jnana Ganga Central School, Bellare </h3><FontAwesomeIcon icon={faExternalLink}/></a>
          <h4>Time : 2013 - 2016 </h4>
          <ul>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Graduated with a CGPA of 10</li>
          </ul>
        </div>
        <div className='experience-card'>
          <h2>PRE UNIVERSITY</h2>
          <a href='https://www.narayanagroup.com/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <h3>SCHOOL : NARAYANA PU COLLEGE </h3><FontAwesomeIcon icon={faExternalLink}/></a>
          <h4>Time : 2016 - 2018</h4>
          <ul>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Obtained a state rank of 516 in CET Exam</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Obtained a category rank of 3700 in JEE Mains Exam</li>
          </ul>
        </div>
        <div className='experience-card'>
          <h2>B.Tech</h2>
          <a href='https://www.nitk.ac.in/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <h3>SCHOOL : NATIONAL INSTITUTE OF TECHNOLOGY, KARNATAKA </h3><FontAwesomeIcon icon={faExternalLink}/></a>
          <h4>Time : 2018 - 2022</h4>
          <ul>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Got my B.Tech degree specializing in Information Technology</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Creative Committee head for the 2022 version of cultural fest of NITK, Incident</li>
          </ul>
        </div>

      </div>
    </div >
  );
};

export default Education;
