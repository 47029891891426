import React from 'react';
import '../stylesheets/SideBar.css';
import myPhoto from '../assets/my_photo.jpg'
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as LeetCodeIcon } from '../assets/leetcode-svgrepo-com.svg';


const Sidebar = () => {
  return (
    <div className="sidebar">
      <h1>ABOUT ME</h1>
      <div className="profile-photo">
        <img src={myPhoto} alt="Profile" />
      </div>
      <div className="social-media-links">
        <a href='https://www.instagram.com/akshithbellare/?hl=en' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} className='instagram-icon icon' />
        </a>
        <a href='https://www.youtube.com/watch?v=lqQD881oOOA' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} className='youtube-icon icon' />
        </a>
        <a href='https://github.com/AkshithBellare' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className='github-icon icon' />
        </a>
        <a href='https://www.linkedin.com/in/akshithbellare/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} className='linkedin-icon icon' />
        </a>
        <a href='https://x.com/xakshithbellare' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} className='linkedin-icon icon' />
        </a>
        <a href='https://leetcode.com/u/AkshithBellare/' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <LeetCodeIcon fill='#2f2f2f' className='icon' />
        </a>
      </div>
      <div className='about-me-text'>
        <p>Hi visitor! I am a backend engineer specializing in converting business requirements into Microservices. I mainly work with dotnet core and camunda bpm for process orchestration.I like art, please visit my instagram to check some of my artworks! Please visit my Github to check some of my projects. I post videos sometimes on Youtube</p>
      </div>
    </div>
  );
};

export default Sidebar;
