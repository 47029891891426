import React from 'react';
import '../stylesheets/Experience.css'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

const Experience = () => {
  return (
    <div className='experience'>
      <h1>EXPERIENCE</h1>

      <div className='experience-cards'>
        <div className='experience-card'>
          <h2>SUMMER INTERN '21</h2>
          <a href='https://www.gep.com' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <h3>Organization : GEP WORLDWIDE </h3><FontAwesomeIcon icon={faExternalLink}/></a>
          <h4>Time : May 2021 - July 2021 </h4>
          <ul>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Interned in the architecture team. Developed a Proof of Concept for a Login feature, using Caching technologies like Hazelcast, Redis.</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Developed a microservice and front end application using dotnet.</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Our team won an internal hackathon "Techathon" where we developed a notification feature using technologies such as MongoDB change streams, pusher JS and Angular with plugin architecture for frontend.</li>
          </ul>
          <div className='skillpills'>
            <div className='skill-pill'>MongoDB</div>
            <div className='skill-pill'>Microservices</div>
          </div>
        </div>
        <div className='experience-card'>
          <h2>SOFTWARE ENGINEER</h2>
          <a href='https://www.gep.com' class='social-media-link' target='_blank' rel="noopener noreferrer">
          <h3>Organization : GEP WORLDWIDE </h3><FontAwesomeIcon icon={faExternalLink}/></a>
          <h4>Time : July 2022 - Present</h4>
          <ul>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>Backend Engineer, Third Party Risk Assessment Team</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>As a Backend Engineer, I specialize in transforming complex business requirements into robust, scalable solutions using Camunda BPM, .NET Core, and MongoDB</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>My role involves designing and implementing backend systems that are both reliable and efficient, ensuring seamless integration and functionality across various platforms.</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>I leverage NewRelic for proactive monitoring and troubleshooting, enabling the swift resolution of issues and maintaining optimal system performance.</li>
            <li><FontAwesomeIcon icon={faCaretRight} className='careticon'/>In my team, I hold ownership of a mission-critical feature that serves multiple high-profile clients, impacting thousands of users daily..</li>
          </ul>
          <div className='skillpills'>
            <div className='skill-pill'>Camunda BPM</div>
            <div className='skill-pill'>MongoDB</div>
            <div className='skill-pill'>Microservices</div>
          </div>
        </div>

      </div>
    </div >
  );
};

export default Experience;
