// LandingPage.js
import React, { useState, useEffect } from 'react';
import { ReactTyped } from 'react-typed';
import '../stylesheets/LandingPage.css'; // Importing CSS file for styling

const LandingPage = () => {
    const [showTypedText, setShowTypedText] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTypedText(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const displayStrings = [
        "Software Engineer",
        "NITK 2022",
        "Artist"
    ]

    return (
        <div className="landing-container">
            <div className='landing-text-container'>
                <h1 className="landing-text">AKSHITH BELLARE</h1>
            </div>
            <h2 className="landing-sub-text"> 
                {showTypedText && (
                    <ReactTyped
                        strings={displayStrings}
                        typeSpeed={50}
                        backSpeed={30}
                        backDelay={1500}
                        startDelay={500}
                        loop={true}
                        cursorChar={'|'}
                        className="typed-text"
                    />
                )}
            </h2>
        </div>
    );
};

export default LandingPage;

