
import React from 'react';
import '../stylesheets/BottomBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import myPhoto from '../assets/my_photo.jpg'
import { Link } from 'react-scroll';
import ThemeToggleButton from './ThemeToggleButton';

const BottomBar = ({ className }) => {
    return (
        <div className={`bottom-bar${className}`}>
            <nav className="bottom-bar-ul">
                <div className='bottom-bar-item'>
                    <div className='bottom-bar-item-icon toggle-theme-bottom-bar'>
                        <ThemeToggleButton/>
                    </div>
                    <span>Toggle Theme</span>
                </div>
                <Link to="experience" smooth={true} duration={500}>
                    <div className='bottom-bar-item'>
                        <div className='bottom-bar-item-icon'>
                            <FontAwesomeIcon icon={faSuitcase} color='#f5f5f5' />
                        </div>
                        <span>Experience</span>
                    </div>
                </Link>
                <Link to="education" smooth={true} duration={500}>
                    <div className='bottom-bar-item'>
                        <div className='bottom-bar-item-icon'>
                            <FontAwesomeIcon icon={faGraduationCap} color='#f5f5f5' />
                        </div>
                        <span>Education</span>
                    </div>
                </Link>
                <Link to="contactme" smooth={true} duration={500}>
                    <div className='bottom-bar-item'>
                        <div className='bottom-bar-item-icon'>
                            <FontAwesomeIcon icon={faPhone} color='#f5f5f5' />
                        </div>
                        <span>Contact Me</span>
                    </div>
                </Link>
                <Link to="sidebar" smooth={true} duration={500}>
                    <div className='bottom-bar-item'>
                        <div className='bottom-bar-item-icon'>
                            <img src={myPhoto} alt="Profile" />
                        </div>
                        <span>About Me</span>
                    </div>
                </Link>
            </nav>
        </div>

    );
};

export default BottomBar;