import React from 'react';
import useTheme from '../hooks/useTheme.js'; // Make sure to import the hook
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ThemeToggleButton = () => {
  const [theme, toggleTheme] = useTheme();

  return (
    <div onClick={toggleTheme} style={{ cursor: 'pointer' }}>
      {theme === 'light' ? <FontAwesomeIcon icon={faSun} color='#FDB813' /> : <FontAwesomeIcon icon={faMoon} color='#000000'/>}
    </div>
  );
};

export default ThemeToggleButton;
