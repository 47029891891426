
import React from 'react';
import '../stylesheets/Header.css';
import { Link } from 'react-scroll';
import ThemeToggleButton from './ThemeToggleButton';

function Header({ className }) {
  return (
    <header className={className}>
      <div>
        <span className='logo-text' >AKSHITH BELLARE</span>
      </div>
      <div className='nav-links'>
        <nav>
          <ul className='nav-links-ul'>
          <Link to="sidebar" smooth={true} duration={500} offset={-70}>
            <li><a href="#home">ABOUT</a></li>
          </Link>
          <Link to="experience" smooth={true} duration={500} offset={-70}>
            <li><a href="#home">EXPERIENCE</a></li>
          </Link>
          <Link to="education" smooth={true} duration={500} offset={-70}>
            <li><a href="#home">EDUCATION</a></li>
          </Link>
          <Link to="contactme" smooth={true} duration={500} offset={-70}>
            <li><a href="#home">CONTACTME</a></li>
          </Link>
          <ThemeToggleButton className='themeToggleButton'/>
          </ul>
        </nav>
      </div>

    </header>
  );
};

export default Header;